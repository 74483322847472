.questionBanner {
    display: flex;
    margin-top: 2rem;
}

.questionLine {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: -8px;
    margin-right: 1rem;
}

    .questionLine span {
        background: #fff;
        padding: 0 10px;
    }

.addRemoveAnswerOption {
    display: flex;
    margin-top: 1rem;
    margin-left: 1rem;
}

.questionMenu {
    margin-left: 1.5rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
}