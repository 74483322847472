body {
    margin: 0;
    font-family: "Roboto",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
    margin-block-start: 0em;
    margin-block-end: 1.0em;
}

p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

#root {
    height: 100vh;
}