.prompt {
    width: 240px;
    height: 480px;

    position:absolute;
    left:0; right:0;
    top:0; bottom:0;
    margin:auto;

    max-width:100%;
    max-height:100%;
    overflow:hidden;
}