.cardContent {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.cardIcon {
    font-size: 1.5rem;
    padding-right: 0.8rem;
}

.cardTitle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 1rem;
}

    .cardTitle > span {
        font-size: 1.9rem;
    }