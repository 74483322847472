.surveyContent {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.survey {
    padding: 1.5rem;
}

.surveyInfo {
    display: flex;
    justify-content: center;
}

    .surveyInfo > div > div {
        width: 200px;
        padding-top: 1rem;
        padding-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
    }

        .surveyInfo > div > div > img {
            width: 200px;
        }

    .surveyInfo > div > h1, .surveyInfo > div > p {
        text-align: center;
    }