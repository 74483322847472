.loading {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
}

    .loading > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
